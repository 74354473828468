import { Fragment } from "react";
import Terms from "../components/Terms";

export function TermsPage() {
  return (
    <Fragment>
      <Terms />
    </Fragment>
  );
}

export default TermsPage;
