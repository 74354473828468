import { Fragment } from "react";
import { Card } from "./Card";
import { Download } from "./Download";
import { Hero } from "./Hero";
import { Works } from "./Works";

export function Home() {
  return (
    <Fragment>
      <Hero />
      <Card />
      <Works />
      <Download />
    </Fragment>
  );
}

export default Home;
