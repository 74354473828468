import React from "react";

export function Top() {
  return (
    <div
      style={{ fontFamily: "Gilroy" }}
      className="flex justify-center py-12 mt-16 bg-secondary text-white"
    >
      <div>
        <h4 className="text-5xl md:text-8xl text-center py-4 leading-12">
          <span className="text-primary">
            Building the <span className="text-white">leading</span> <br />{" "}
            Financing platform for <br />{" "}
            <span className="text-white">African</span> businesses.{" "}
          </span>
        </h4>
      </div>
    </div>
  );
}
