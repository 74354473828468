import playstore from "../assets/playstore.png";
import appstore from "../assets/appstore.png";

export function Hero() {
  return (
    <div className="flex justify-center py-12 mt-16">
      <div style={{ fontFamily: "Gilroy" }}>
        <h4 className="text-5xl md:text-8xl text-primary text-center">
          <span className="text-secondary">Unlocking</span> <br />
          the benefits of
          <br />
          <span className="text-secondary">aggregated</span> <br />
          capital
        </h4>

        <div className="flex justify-center mt-12">
          <span className="mr-4 cursor-pointer">
            <a href="https://play.google.com/store/apps/details?id=io.seedsix.app">
              <img className="h-8 md:h-12" src={playstore} alt="playstore" />
            </a>
          </span>
          <span className="cursor-pointer">
            <a href="https://testflight.apple.com/join/Yb6Cm3VP">
              <img className="h-8 md:h-12" src={appstore} alt="appstore" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
