import React from "react";

export function Top() {
  return (
    <div
      style={{ fontFamily: "Gilroy" }}
      className="flex justify-center py-12 mt-16 bg-secondary text-white"
    >
      <div>
        <h4 className="text-3xl px-2 md:text-7xl text-center py-4 leading-12">
          <span className="text-primary">
            <span className="text-white">Streamlining</span> methods that are{" "}
            <br /> central to
            <span className="text-white">African business</span> culture <br />
            and financial heritage while <br /> minimising{" "}
            <span className="text-white">DEFAULT</span>
          </span>
        </h4>
      </div>
    </div>
  );
}
