import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import menu from "../assets/menu.png";
import { useTransition, animated } from "react-spring";

export function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuSlide = useTransition(menuOpen, null, {
    from: { opacity: 0, transform: "translateX(-100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(-100%)" },
  });

  return (
    <Fragment>
      <div
        style={{ fontFamily: "Gilroy-light" }}
        className="fixed z-20 top-0 left-0 shadow-sm w-full bg-white"
      >
        <div className="text-black font-semibold px-4 md:px-12">
          <div className="flex justify-between items-center">
            <Link onClick={() => setMenuOpen(false)} to="/">
              <span className="cursor-pointer">
                <img
                  className="h-20 w-20 object-contain"
                  src={logo}
                  alt="logo"
                />
              </span>
            </Link>

            <span className="hidden md:inline-block">
              <ul className="flex">
                <li className="text-primary hover:text-secondary transition-all cursor-pointer mr-4 lg:mr-8">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="text-primary hover:text-secondary transition-all cursor-pointer mr-4 lg:mr-8">
                  <Link to="/how-it-works">How it works</Link>
                </li>
                <li className="text-primary hover:text-secondary transition-all cursor-pointer mr-4 lg:mr-8">
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://mjdcx1n8nf8.typeform.com/to/OoiBgQAI"
                  >
                    For Entrepreneurs
                  </a>
                </li>
                <li className="text-primary hover:text-secondary transition-all cursor-pointer mr-4">
                  News & Events
                </li>
              </ul>
            </span>

            <span
              className="md:hidden font-semibold flex cursor-pointer"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span>
                <img className="h-5 w-8" src={menu} alt="" />
              </span>
            </span>
          </div>
        </div>

        <div className={menuOpen ? "block" : "hidden"}>
          {menuSlide.map(
            ({ item, key, props }) =>
              item && (
                <animated.div
                  style={props}
                  key={key}
                  className="fixed w-full h-full bg-white"
                >
                  <div>
                    <ul className="text-center text-lg font-semibold">
                      <li
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                        className="text-primary hover:text-secondary transition-all cursor-pointer py-4"
                      >
                        <Link to="/about">About Us</Link>
                      </li>
                      <li
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                        className="text-primary hover:text-secondary transition-all cursor-pointer py-4"
                      >
                        <Link to="/how-it-works">How it works</Link>
                      </li>
                      <li
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                        className="text-primary hover:text-secondary transition-all cursor-pointer py-4"
                      >
                        <a href="https://mjdcx1n8nf8.typeform.com/to/OoiBgQAI">
                          For Entrepreneurs
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                        className="text-primary hover:text-secondary transition-all cursor-pointer py-4"
                      >
                        News & Events
                      </li>
                    </ul>
                  </div>
                </animated.div>
              )
          )}
        </div>
      </div>
    </Fragment>
  );
}
