import meeting from "../assets/meeting.png";
import curve from "../assets/curve.png";
import { Fragment } from "react";

export function Goals() {
  return (
    <Fragment>
      <div className="md:flex justify-around mt-4 w-full px-4 py-12">
        <div className="md:w-2/4">
          <h4
            style={{ fontFamily: "Gilroy" }}
            className="text-3xl text-primary"
          >
            Who We Are
          </h4>
          <div className="pt-2">
            <span>
              <img className="w-3/4 h-6" src={curve} alt="" />
            </span>
          </div>

          <div className="mt-4">
            <p style={{ fontFamily: "mulish" }} className="py-4">
              Founded in 2021 in Kampala - Uganda, SeedSix is an East Afcrican
              Investment company with a foundation on the premise that we can do
              a lot more together. SeedSix utilizes the power aggregated capital
              to bring together investrors of similar interests to empower
              businesses and cultivate development within their own communities.
            </p>

            <p style={{ fontFamily: "mulish" }} className="py-4">
              <span className="block font-bold mb-1">SMEs</span> We are
              passionate about supporting these businesses that are the backbone
              of our economies. we endeavor to provide solutions that truly
              serve their needs. our approach is based on innovation, education
              and community
            </p>

            <p style={{ fontFamily: "mulish" }} className="py-4">
              <span className="block font-bold mb-1">Real Estate</span> At
              SeedSix we believe that the cornerstone of a developing nation and
              wealth creation is the ability to BUILD! Our real estate fund
              allows inestors to be part and parcel of building quality property
              that will create an undeniable legacy while giving strong
              investment returns
            </p>
          </div>
        </div>

        <div className="md:w-1/4 pt-4">
          <span>
            <img
              className="md:h-full object-contain"
              src={meeting}
              alt="meeting"
            />
          </span>
        </div>
      </div>
    </Fragment>
  );
}
