import { Fragment } from "react";
import img1 from "../assets/E_WEB_01.png";
import img5 from "../assets/E_WEB_05.png";
import img8 from "../assets/E_WEB_08.png";
import img9 from "../assets/E_WEB_09.png";

export function SDG() {
  return (
    <Fragment>
      <div className="bg-primary py-16 text-white">
        <h4
          style={{ fontFamily: "Gilroy" }}
          className="text-3xl mt-4 text-center text-white font-semibold"
        >
          SDGs Inclination
        </h4>

        <div className="md:flex flex-wrap justify-around pt-4 px-4">
          <div className="md:w-2/5 md:flex w-full justify-around">
            <div className="md:w-1/3 mt-10">
              <span className="mr-2">
                <img
                  className="h-24 md:h-36 w-auto object-contain md:w-auto"
                  src={img1}
                  alt=""
                />
              </span>
            </div>
            <div className="md:w-2/3">
              {" "}
              <h4
                style={{ fontFamily: "Gilroy" }}
                className="text-lg font-semibold mb-4 mt-8"
              >
                {" "}
                Goal 1: No poverty
              </h4>
              <p style={{ fontFamily: "mulish" }}>
                Nearly 22% of Ugandans live in poverty. with 75% of the
                population living in rural areas predominantly on subsistence
                farming, SeedSix will avail them with the opportunity to make
                extra income by signing up with our local agents which would
                give them the opportunity to credit that they would have
                otherwise not had access to. this will enable them to grow their
                farms and increase their farms and increase their welfare to a
                level above the poverty
              </p>
            </div>
          </div>

          <div className="md:w-2/5 md:flex w-full justify-around">
            <div className="md:w-1/3 mt-10">
              <span className="mr-2">
                <img
                  className="h-24 md:h-36 w-auto object-contain md:w-auto"
                  src={img5}
                  alt=""
                />
              </span>
            </div>
            <div className="md:w-2/3">
              <h4
                style={{ fontFamily: "Gilroy" }}
                className="text-lg font-semibold mb-4 mt-8"
              >
                Goal 5: Gender Quality
              </h4>
              <p>
                Africa is the only region in the world where women make up
                majority of the entreprenuers. Despite their unparalleled
                success, Africa{"'"}s female owned businesses post profits 38%
                lower than those owned by male entrepreneurs and have access to
                six times less capital. SeedSix strives to close this gap
                through availing women with access to capital
              </p>
            </div>
          </div>

          <div className="md:w-2/5 md:flex w-full justify-around">
            <div className="md:w-1/3 mt-10">
              <span className="mr-2">
                <img
                  className="h-24 md:h-36 w-auto object-contain md:w-auto"
                  src={img8}
                  alt=""
                />
              </span>
            </div>
            <div className="md:w-2/3">
              <h4
                style={{ fontFamily: "Gilroy" }}
                className="text-lg font-semibold mb-4 mt-8"
              >
                Goal 8: Decent work & Economic growth
              </h4>
              <p>
                We create jobs by supporting small businesses, improving the
                supply of credit from communities to help micro & SMEs grow.
              </p>
            </div>
          </div>

          <div
            style={{ fontFamily: "mulish" }}
            className="md:w-2/5 md:flex w-full justify-around"
          >
            <div className="md:w-1/3 mt-10">
              <span className="mr-2">
                <img
                  className="h-24 md:h-36 w-auto object-contain md:w-auto"
                  src={img9}
                  alt=""
                />
              </span>
            </div>
            <div className="md:w-2/3">
              <h4 className="text-lg font-semibold mb-4 mt-8">
                Goal 9: Industry, Innovation & Infrastructure
              </h4>
              <p style={{ fontFamily: "mulish" }}>
                Through our real estate offerings we provide long-term support
                to improve development of infratsructure and promote economic
                development
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
