import { Fragment } from "react";
import Home from "../components/Home";

export function HomePage() {
  return (
    <Fragment>
      <Home />
    </Fragment>
  );
}

export default HomePage;
