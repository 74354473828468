import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import Home from "./views/HomePage";
import About from "./views/AboutPage";
import Works from "./views/WorksPage";
import Terms from "./views/TermsPage";
import Risks from "./views/Risks";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/how-it-works" element={<Works />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/risk" element={<Risks />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
