import { Fragment } from "react";
import How from "../components/How";

export function Works() {
  return (
    <Fragment>
      <How />
    </Fragment>
  );
}

export default Works;
