import solution from "../assets/solution.png";
import curve from "../assets/curve.png";
import reverse from "../assets/reverse-curve.png";
import img1 from "../assets/E_WEB_01.png";
import img5 from "../assets/E_WEB_05.png";
import img8 from "../assets/E_WEB_08.png";
import img9 from "../assets/E_WEB_09.png";

import { Fragment } from "react";

export function Card() {
  return (
    <Fragment>
      <div className="md:flex justify-around mt-12 w-full px-4">
        <div className="md:w-2/4">
          <h4
            style={{ fontFamily: "Gilroy" }}
            className="text-3xl text-primary"
          >
            African <span className="text-secondary">solutions</span> to African
            problems
          </h4>
          <div className="pt-2">
            <span>
              <img className="w-3/4 h-6" src={curve} alt="" />
            </span>
          </div>

          <div style={{ fontFamily: "mulish" }} className="mt-4">
            <p>
              Our primary target market, Uganda, is the most entrepreneurial
              country in the world. 28% of Ugandans hold ownership in a
              business. We are offering ”first-stage funding” for startups
              looking to scale operations and expand to meet market demand and
              established SMEs requiring short-term working capital financing.
            </p>
          </div>
        </div>

        <div className="md:w-1/4 pt-4">
          <span>
            <img className="h-64" src={solution} alt="" />
          </span>
        </div>
      </div>

      <div className="md:flex justify-around mt-12 w-full px-4">
        <div className="md:w-2/4">
          <h4
            style={{ fontFamily: "Gilroy" }}
            className="text-3xl order-last text-primary"
          >
            We are <span className="text-secondary">commited</span> to impact...
          </h4>
          <div className="pt-2">
            <span>
              <img className="w-3/4 h-6" src={reverse} alt="" />
            </span>
          </div>

          <div className="mt-4">
            <p style={{ fontFamily: "mulish" }}>
              Identifying with and incorporating the Sustainable Development
              Goals is a huge deal for us because humanity is a currency that
              never goes out of style or value. Just as Martin Luther King, Jr.
              put it, “An individual has not started living until he can rise
              above the narrow confines of his individualistic concerns to the
              broader concerns of all humanity.”
            </p>
          </div>
        </div>

        <div className="md:w-1/4 mt-6 md:mt-0 order-first flex justify-around">
          <div>
            {" "}
            <span className="mr-2">
              <img className="h-28" src={img1} alt="" />
            </span>
            <span>
              <img className="h-28" src={img5} alt="" />
            </span>
          </div>

          <div>
            {" "}
            <span className="mr-2">
              <img className="h-28" src={img8} alt="" />
            </span>
            <span>
              <img className="h-28" src={img9} alt="" />
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
