import { Fragment } from "react";
import About from "../components/About";

export function AboutPage() {
  return (
    <Fragment>
      <About />
    </Fragment>
  );
}

export default AboutPage;
