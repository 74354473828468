import { Fragment } from "react";
import Risks from "../components/Risk";

export function RisksPage() {
  return (
    <Fragment>
      <Risks />
    </Fragment>
  );
}

export default RisksPage;
