import borrower from "../assets/borrower.png";
import lender from "../assets/lender.png";
import { Fragment } from "react";

export function Join() {
  return (
    <Fragment>
      <div className="md:flex justify-around mt-12 w-full px-4">
        <div className="md:w-2/4">
          <h4
            style={{ fontFamily: "Gilroy" }}
            className="text-3xl order-last text-primary"
          >
            How To Join As <br /> A Lender
          </h4>

          <div className="mt-4">
            <p style={{ fontFamily: "mulish" }}>
              Joining as a lender is simple; all you have to do is sign up by
              answering a few questions e.g. your name, date of birth,
              businesses you are interested in (Micro, Grow, Real Estate &
              Group) and clicking submit. This will take you to your account
              which has a personalised dashboard that will help you access
              investment opportunities in your community that are primed to your
              desires and enable you to track your investments efficiently too.
            </p>
          </div>
        </div>

        <div className="md:w-1/4 pt-4 mb-4 order-first">
          <span>
            <img className="h-64" src={lender} alt="" />
          </span>
        </div>
      </div>

      <div className="md:flex justify-around mt-12  w-full px-4">
        <div className="md:w-2/4">
          <h4
            style={{ fontFamily: "Gilroy" }}
            className="text-3xl text-primary"
          >
            How To Join As <br /> A Borrower
          </h4>

          <div className="mt-4">
            <p style={{ fontFamily: "mulish" }}>
              As a borrower, you will sign up with information about your
              business eg nature of the business, what the money is going to be
              used for, payment terms and duration. You will also have the
              opportunity to market your business with tools such as videos.
              There will also be allowances for documents of previous financial
              history that prove your credibility to creditors and investors.
              Prompt payment improves your credit rating score on SeedSix which
              in turn increases your likelihood of getting debt and further
              investment later on down the line.
            </p>
          </div>
        </div>

        <div className="md:w-1/4 pt-4 mb-4">
          <span>
            <img className="h-64" src={borrower} alt="" />
          </span>
        </div>
      </div>
    </Fragment>
  );
}
