import { Fragment } from "react";
import { Goals } from "./Goals";
import { Mission } from "./Mission";
import { SDG } from "./SDG";

import { Top } from "./Top";
import { Values } from "./Values";

export function About() {
  return (
    <Fragment>
      <Top />
      <Goals />
      <Mission />
      <Values />
      <SDG />
    </Fragment>
  );
}

export default About;
