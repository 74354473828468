import chat from "../assets/chatbot.png";
import curve from "../assets/curve.png";
import { Fragment } from "react";

export function Offer() {
  return (
    <Fragment>
      <div className="md:flex justify-around mt-4 w-full px-4 py-12">
        <div className="md:w-2/4">
          <h4
            style={{ fontFamily: "Gilroy" }}
            className="text-4xl text-primary"
          >
            Our Offering
          </h4>
          <div className="pt-2">
            <span>
              <img className="w-2/4 h-6" src={curve} alt="" />
            </span>
          </div>

          <div className="mt-4">
            <p style={{ fontFamily: "mulish" }} className="py-4">
              <span className="inline-block font-bold mb-1">
                Aggregated Capital -{" "}
              </span>{" "}
              We connect investors seeking lucrative opportunities to grow their
              money to businesses with short to mid-term capital needs hereby
              creating an investment eco-system of mutual benefit to both
              parties
            </p>

            <p style={{ fontFamily: "mulish" }} className="py-4">
              <span className="inline-block font-bold mb-1">
                Digital Access –{" "}
              </span>{" "}
              We have built a mobile-based app that makes the fundraising,
              investment and monitoring process seamless. Information is always
              on the tip of your fingers Professional Due-diligence – to ensure
              investors’ peace of mind, opportunities displayed on our platform
              undergo stringent due diligence and investment readiness processes
              to ensure safety and legitimacy
            </p>

            <p style={{ fontFamily: "mulish" }} className="py-4">
              <span className="inline-block font-bold mb-1">
                Education and Support –
              </span>{" "}
              We believe that excellence is achieved in tapping into our
              people’s capacity to learn. We utilise tested methods to assist
              both businesses - on better methods to grow their services - and
              investors - on the risk/reward of investing
            </p>

            <p style={{ fontFamily: "mulish" }} className="py-4">
              <span className="inline-block font-bold mb-1">
                An African Solution to an African Problem –
              </span>{" "}
              We are localising financing solutions by streamlining methods that
              are central to African business culture and financial heritage
              while minimising DEFAULT
            </p>
          </div>
        </div>

        <div className="md:w-1/4 pt-4">
          <span>
            <img className="md:h-full object-contain" src={chat} alt="chat" />
          </span>
        </div>
      </div>
    </Fragment>
  );
}
