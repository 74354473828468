import pay from "../assets/payment.png";
import { Link } from "react-router-dom";

import { Fragment } from "react";

export function Footer() {
  return (
    <Fragment>
      <div
        style={{ fontFamily: "Gilroy" }}
        className="md:flex justify-around w-full px-4 py-12 bg-gray-100"
      >
        <div className="items-center py-2 md:py-12 order-last">
          <div>
            <h4 className="text-xl text-secondary font-semibold">
              Let's talk{" "}
            </h4>
            <h4 className="text-5xl text-primary md:text-6xl font-semibold">
              Invest in a <br /> business today
            </h4>

            <div className="w-full h-2 mt-3 rounded-full bg-secondary"></div>
          </div>
        </div>

        <div className="mt-12 flex justify-around order-first">
          <h4 className="text-5xl text-primary">
            <span className="text-secondary">
              <span>
                <img className="h-48 w-auto" src={pay} alt="" />
              </span>
            </span>
          </h4>
        </div>
      </div>

      <hr />

      <div className="md:flex justify-around w-full px-4 py-4">
        <div className="py-2 md:w-3/4 md:flex justify-between">
          <div className="py-3">
            <ul className="flex justify-center">
              <li className="mr-4 text-primary hover:text-secondary cursor-pointer">
                <Link to="/terms">Terms of use</Link>
              </li>
              <li className="mr-4 text-primary hover:text-secondary cursor-pointer">
                <Link to="/risk">Risk</Link>
              </li>
              <li className="text-primary hover:text-secondary cursor-pointer">
                <Link to="/privacy.pdf">Privacy Policy</Link>
              </li>
            </ul>
          </div>

          <div className="py-3 flex justify-center">
            <span>
              <span className="text-gray-500">&copy; 2022</span> Seed Six.{" "}
              <span className="text-gray-500">All rights reserved</span>
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
