import React from "react";
import pdfFile from "../assets/terms.pdf";

function Terms() {
  return (
    <div className="pt-20">
      <object
        style={{ height: "100vh" }}
        data={pdfFile}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Terms of use <a href={pdfFile}>PDF</a>
        </p>
      </object>
    </div>
  );
}

export default Terms;
