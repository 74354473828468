import React from "react";
import pdfFile from "../assets/risks.pdf";

function Risks() {
  return (
    <div className="pt-20">
      <object
        style={{ height: "100vh" }}
        data={pdfFile}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Risks <a href={pdfFile}>PDF</a>
        </p>
      </object>
    </div>
  );
}

export default Risks;
