import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";

export function ScrollToTop(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <Fragment>{props.children}</Fragment>;
}

export default ScrollToTop;
