import line from "../assets/line.png";
import curve from "../assets/curve.png";
import { Link } from "react-router-dom";

import { Fragment } from "react";

export function Works() {
  return (
    <Fragment>
      <div className="bg-secondary text-white py-12 mt-8">
        <h3 style={{ fontFamily: "Gilroy" }} className="text-center text-4xl">
          How it <span className="text-primary">works</span>
        </h3>
        <div className="md:flex justify-around mt-4 w-full px-4">
          <div className="md:w-1/4 mt-4">
            <h4
              style={{ fontFamily: "Gilroy" }}
              className="text-3xl text-center pt-2"
            >
              Aggregated <br /> Capital
            </h4>
            <div className="flex justify-center">
              <span>
                <img className="w-full" src={line} alt="" />
              </span>
            </div>
            <div className="pt-2">
              <span>
                <img className="w-3/4 h-6" src={curve} alt="" />
              </span>
            </div>

            <div className="mt-4">
              <p style={{ fontFamily: "mulish" }}>
                We connect investors seeking lucrative opportunities to grow
                their money to businesses with short to mid-term capital needs
                hereby creating an investment eco-system of mutual benefit to
                both parties
              </p>
            </div>
          </div>

          <div className="md:w-1/4">
            <h4
              style={{ fontFamily: "Gilroy" }}
              className="text-3xl text-center mt-4"
            >
              Digital <br /> Access
            </h4>
            <div className="flex justify-center pt-2">
              <span>
                <img className="w-full" src={line} alt="" />
              </span>
            </div>
            <div className="pt-2">
              <span>
                <img className="w-3/4 h-6" src={curve} alt="" />
              </span>
            </div>

            <div className="mt-4">
              <p style={{ fontFamily: "mulish" }}>
                We have built a mobile-based app that makes the fundraising,
                investment and monitoring process seamless. Information is
                always on the tip of your fingers
              </p>
            </div>
          </div>

          <div className="md:w-1/4">
            <h4
              style={{ fontFamily: "Gilroy" }}
              className="text-3xl text-center mt-4"
            >
              Education <br /> & Support
            </h4>
            <div className="flex justify-center pt-2">
              <span>
                <img className="w-full" src={line} alt="" />
              </span>
            </div>
            <div className="pt-2">
              <span>
                <img className="w-3/4 h-6" src={curve} alt="" />
              </span>
            </div>

            <div className="mt-4">
              <p style={{ fontFamily: "mulish" }}>
                Identifying with and incorporating the Sustainable Development
                Goals is a huge deal for us because humanity is a currency that
                never goes out of style or value.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8 py-12">
          <div>
            <Link to="/how-it-works">
              {" "}
              <span
                style={{ fontFamily: "Gilroy" }}
                className="uppercase bg-primary hover:border hover:bg-secondary transition-all px-12 py-2 text-xl cursor-pointer"
              >
                Get Started
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
