import { Fragment } from "react";
import innov from "../assets/innovation.png";
import friends from "../assets/friends.png";
import sport from "../assets/sport.png";

export function Values() {
  return (
    <Fragment>
      <div className="bg-secondary py-12 text-primary">
        <h4
          style={{ fontFamily: "Gilroy" }}
          className="text-3xl text-center text-white"
        >
          Core Values
        </h4>

        <div className="md:flex flex-wrap justify-around pt-12 px-4">
          <div className="md:w-1/4">
            <div className="max-w-sm h-96 px-2 mb-6 bg-white py-4 rounded overflow-hidden shadow-lg">
              <img
                className="w-full h-36 object-contain"
                src={innov}
                alt="innovation"
              />
              <div className="px-4 py-4">
                <div
                  style={{ fontFamily: "Gilroy" }}
                  className="font-bold text-xl mb-2 text-center"
                >
                  Innovation
                </div>
                <p
                  style={{ fontFamily: "mulish" }}
                  className="text-primary text-base text-center leading-5"
                >
                  At SeedSix we believe in relentlessly searching for new
                  solutions, questioning assumptions and encouraging new ideas
                </p>
              </div>
            </div>
          </div>

          <div className="md:w-1/4">
            <div className="max-w-sm h-96 px-2  mb-6 bg-white py-4 rounded overflow-hidden shadow-lg">
              <img
                className="w-full h-36 object-contain"
                src={sport}
                alt="integrity"
              />
              <div className="px-4 py-4">
                <div
                  style={{ fontFamily: "Gilroy" }}
                  className="font-bold text-xl mb-2 text-center"
                >
                  Integrity
                </div>
                <p
                  style={{ fontFamily: "mulish" }}
                  className="text-primary text-base text-center leading-5"
                >
                  Integrity creates trust. As an organization, collectively, its
                  our most valuable asset individually, its the constant choice
                  to infuse every action with honesty, fairness and respect for
                  clients and colleagues alike
                </p>
              </div>
            </div>
          </div>

          <div className="md:w-1/4">
            <div className="max-w-sm h-96 px-2 mb-6 bg-white py-4 rounded overflow-hidden shadow-lg">
              <img
                className="w-full h-36 object-contain"
                src={friends}
                alt="distinction"
              />
              <div className="px-4 py-4">
                <div
                  style={{ fontFamily: "Gilroy" }}
                  className="font-bold text-xl mb-2 text-center"
                >
                  Distinction
                </div>
                <p
                  style={{ fontFamily: "mulish" }}
                  className="text-primary text-base text-center leading-5"
                >
                  Creating a difference in communities through an ethic of
                  excellence sets us apart through continuous improvement and
                  raising the bar of our offerings that exceed our clients
                  expectations
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
