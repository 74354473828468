import { Fragment } from "react";
import { Offer } from "./Offer";

import { Top } from "./TopWorks";
import { Board } from "./Board";
import { Join } from "./Join";

export function About() {
  return (
    <Fragment>
      <Top />
      <Offer />
      <Board />
      <Join />
    </Fragment>
  );
}

export default About;
