import { Fragment } from "react";
import mission from "../assets/mission.png";
import vision from "../assets/focus.png";

export function Mission() {
  return (
    <Fragment>
      <h4
        style={{ fontFamily: "Gilroy" }}
        className="text-3xl text-center text-primary"
      >
        Mission & Vision
      </h4>

      <div className="md:flex pb-12 justify-around mt-12 w-full px-4">
        <div className="md:w-1/4">
          <div className="mt-4">
            <div className="flex justify-center md:justify-start">
              <span>
                <img className="h-12" src={vision} alt="vision" />
              </span>
            </div>
            <p style={{ fontFamily: "mulish" }} className="py-4">
              <span
                style={{ fontFamily: "Gilroy" }}
                className="block font-bold mb-1 text-2xl text-center md:text-left text-secondary"
              >
                Vision
              </span>{" "}
              At To foster inclusive growth of communities through
              infrastructure development & sustainable solutions to the
              financing needs of SMEs in Africa
            </p>
          </div>
        </div>

        <div className="md:w-1/4 pt-4">
          <div className="flex justify-center md:justify-start">
            <span>
              <img className="h-12" src={mission} alt="mission" />
            </span>
          </div>
          <p style={{ fontFamily: "mulish" }} className="py-4">
            <span
              style={{ fontFamily: "Gilroy" }}
              className="block font-bold mb-1 text-2xl text-center md:text-left text-secondary"
            >
              Mission
            </span>{" "}
            Unlocking opportunities and enabling client & investor growth
            through aggregated capital
          </p>
        </div>
      </div>
    </Fragment>
  );
}
