import { Fragment } from "react";

export function Board() {
  return (
    <Fragment>
      <div className="bg-secondary py-12 text-white">
        <div className="md:flex flex-wrap justify-around pt-4 px-4">
          <div className="md:w-2/5">
            <p style={{ fontFamily: "mulish" }}>
              Seed Six carries out due diligence on the company to assess its
              Credibility, investment readiness & deal price.
              <br />
              <br />
              Based on the outcome of the due diligence assessment, SeedSix
              rejects, recommends for advisory support or lists the company.
              <br />
              <br />
              Listed companies are presented to investors on the Seed Six
              platform who decide to invest based on the merits of each
              business.
              <br />
              <br />
              SeedSix will on behalf of investors carry out the administrative
              arm of the investment by monitoring the business, ensuring
              covenants are met and payments are made in time.
            </p>
          </div>

          <div className="md:w-2/5">
            <div style={{ fontFamily: "mulish" }}>
              A key factor in unlocking the value of most East African
              businesses is introducing structure and proper financial
              housekeeping to the ingenuity of these entrepreneurs <br />
              <br />
              The Seed Six advisory team equips businesses with;
              <br />
              <ol className="list-disc list-inside">
                <li>accounting</li>
                <li> tax</li>
                <li>structuring/legal advice</li>
                <li>internal controls</li>
              </ol>
              to ensure they are set up to be successful
              <br />
              <br />
              Furthermore, SeedSix Advisory creates educational material through
              podcasts and articles on the SeedSix blog to provide a constant
              source for learning for both investors and entrepreneurs
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
