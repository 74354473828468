import rocket from "../assets/rocket.png";
import playstore from "../assets/playstore.png";
import appstore from "../assets/appstore.png";
import { Fragment } from "react";

export function Download() {
  return (
    <Fragment>
      <div className="md:flex justify-around mt-12 w-full px-4 py-12">
        <div className="md:w-2/4">
          <h4
            style={{ fontFamily: "Gilroy" }}
            className="text-5xl text-primary"
          >
            Download{" "}
            <span className="text-secondary">
              the
              <br /> app today
            </span>
          </h4>

          <div className="flex justify-start mt-12">
            <span className="mr-4 cursor-pointer">
              <a href="https://play.google.com/store/apps/details?id=io.seedsix.app">
                <img className="h-8 md:h-12" src={playstore} alt="playstore" />
              </a>
            </span>
            <span className="cursor-pointer">
            <a href="https://testflight.apple.com/join/Yb6Cm3VP">
              <img className="h-8 md:h-12" src={appstore} alt="appstore" />
              </a>
            </span>
          </div>
        </div>

        <div className="md:w-1/4 pt-4 mt-12">
          <span>
            <img className="h-64" src={rocket} alt="" />
          </span>
        </div>
      </div>
    </Fragment>
  );
}
